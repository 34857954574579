<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 122.88 122.88"
    style="enable-background: new 0 0 122.88 122.88"
    xml:space="preserve"
    width="64"
    height="64"
  >
    <g>
      <path
        class="st0"
        d="M61.44,0l13.52,10.99l17.2-2.76l6.21,16.28l16.28,6.21l-2.76,17.2l10.99,13.52l-10.99,13.52l2.76,17.2 l-16.28,6.21l-6.21,16.28l-17.2-2.76l-13.52,10.99l-13.52-10.99l-17.2,2.76l-6.21-16.28L8.23,92.16l2.76-17.2L0,61.44l10.99-13.52 l-2.76-17.2l16.28-6.21l6.21-16.28l17.2,2.76L61.44,0L61.44,0z M40.82,56.36h9.83c0.79,0,1.44,0.65,1.44,1.45v19.22 c0,0.79-0.65,1.45-1.44,1.45h-9.83c-0.79,0-1.45-0.65-1.45-1.45V57.81C39.38,57.01,40.03,56.36,40.82,56.36L40.82,56.36z M62.02,41.95c0.76-3.86,7.08-0.3,7.5,5.92c0.13,1.9-0.07,4.12-0.54,6.59l9.03,0c3.76,0.15,7.03,2.84,4.72,7.26 c0.53,1.92,0.61,4.18-0.83,5.07c0.18,3.04-0.66,4.92-2.23,6.41c-0.1,1.52-0.43,2.87-1.16,3.91c-1.21,1.71-2.2,1.3-4.11,1.3H59.15 c-2.42,0-3.73-0.67-5.31-2.65V58.77c4.55-1.23,6.96-7.45,8.18-11.53V41.95L62.02,41.95z M61.44,24.02 c20.67,0,37.42,16.76,37.42,37.42c0,20.67-16.76,37.42-37.42,37.42c-20.67,0-37.42-16.76-37.42-37.42 C24.02,40.77,40.77,24.02,61.44,24.02L61.44,24.02z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'QualityBadgeSVG',
}
</script>

<style scoped>
.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
}
svg {
  fill: #327b3c;
}
</style>

<template>
  <svg
    fill="#fff"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    id="right-arrow"
    data-name="Flat Line"
    xmlns="http://www.w3.org/2000/svg"
    class="icon flat-line"
  >
    <line
      id="primary"
      x1="3"
      y1="12"
      x2="21"
      y2="12"
      style="
        fill: none;
        stroke: rgb(255, 255, 255);
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2;
      "
    ></line>
    <polyline
      id="primary-2"
      data-name="primary"
      points="18 15 21 12 18 9"
      style="
        fill: none;
        stroke: rgb(255, 255, 255);
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2;
      "
    ></polyline>
  </svg>
</template>

<script>
export default {
  name: 'RightArrowSVG',
}
</script>

<style scoped></style>
